import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { HomePageText, STANDARD_CC_FEATURES, PRO_CC_FEATURES } from '../constants/Constants';
import { useAuthContext } from '../hooks/useAuthContext';
import '../styles/pages/home.css';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

const Home = () => {
  const { user, isSubscribed } = useAuthContext();

  return (
    <div>
      <CssBaseline />

      {/* Introduction Section */}
      <Box
        sx={{
          bgcolor: '#85b0de',
          minHeight: '80vh',
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
          gap: '20px',
          p: { xs: '10px', md: '20px' },
        }}
      >
        {/* Left Content */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            p: { xs: 2, md: 10 },
          }}
        >
          <h1>{HomePageText.welcomeMessage}</h1>
          <p>{HomePageText.description}</p>
          <Box sx={{ display: 'flex', gap: '10px', mt: 2 }}>
            <Link to="/create" className="button success">
              {HomePageText.createProposalLink}
            </Link>
            <Link to="/example" className="button primary">
              {HomePageText.exampleProposalLink}
            </Link>
          </Box>
        </Box>

        {/* Right Content */}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <p>Image can go here.</p>
        </Box>
      </Box>

      {/* Key Benefits Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          bgcolor: 'white',
          p: { xs: 4, md: 8 },
          gap: '20px',
        }}
      >
        {/* Centered Header */}
        <h1 style={{ textAlign: 'center', margin: 0 }}>KEY BENEFITS</h1>

        {/* Key Benefits Grid */}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr' },
            gap: '20px',
            width: '100%',
          }}
        >
          {[
            {
              title: 'Streamlined Decision-Making',
              description:
                'Consensus Check simplifies the process of gathering input from your team, making it easier to reach decisions quickly and efficiently.',
            },
            {
              title: 'Asynchronous Collaboration',
              description:
                'Team members can contribute their thoughts and opinions at their convenience, eliminating the need for scheduling conflicts and allowing for more flexible participation.',
            },
            {
              title: 'Centralized Input',
              description:
                'Say goodbye to scattered email chains and text messages. Consensus Check keeps all team input in one place, making it easy to track and reference.',
            },
            {
              title: 'Time-Saving',
              description:
                'Reduce the need for lengthy meetings by gathering initial feedback and identifying areas of consensus beforehand.',
            },
          ].map((benefit, index) => (
            <Box
              key={index}
              sx={{
                bgcolor: '#f9f9f9',
                p: { xs: 3, md: 5 },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                justifyContent: 'center',
                borderRadius: '8px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              }}
            >
              <h3 style={{ marginBottom: '1rem' }}>{benefit.title}</h3>
              <p style={{ margin: 0 }}>{benefit.description}</p>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Teams Section */}
      <Box
        sx={{
          bgcolor: '#f3d361',
          minHeight: '80vh',
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
          gap: '20px',
          p: { xs: 2, md: 10 },
        }}
      >
        {/* Left Content */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            p: { xs: 2, md: 10 },
          }}
        >
          <h1>Perfect for Various Teams</h1>
          <p>Consensus Check is ideal for:</p>
          <ul>
            {[
              'Nonprofit organizations',
              'Business teams',
              'Community groups',
              'Cooperatives',
              'Any group that needs to make collective decisions',
            ].map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <p>Empower your team to contribute thoughts without constraints of scheduling.</p>
        </Box>

        {/* Right Content */}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <p>Image can go here.</p>
        </Box>
      </Box>

      {/* Pricing Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          p: { xs: 3, md: 5 },
          justifyContent: 'flex-start',
          alignItems: 'center',
          bgcolor: 'white',
        }}
      >
        {/* Centered Header */}
        <h1 style={{ textAlign: 'center', margin: 0 }}>PRICING</h1>

        {/* Pricing Cards */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 4,
            justifyContent: 'center',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          {[
            { title: 'BASIC', price: 'FREE', features: STANDARD_CC_FEATURES },
            { title: 'SUPPORTER', price: 'Pay What You Can', features: PRO_CC_FEATURES },
          ].map((plan, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                textAlign: 'center',
                p: { xs: 3, md: 4 },
                bgcolor: '#f9f9f9',
                borderRadius: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                width: { xs: '100%', md: '45%' },
              }}
            >
              <h6 style={{ margin: '0 0 1rem', fontSize: '1.25rem' }}>{plan.title}</h6>
              <p style={{ fontSize: '1.5rem', fontWeight: 'bold', margin: '0 0 1.5rem' }}>{plan.price}</p>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  mb: 3,
                }}
              >
                {plan.features.map((feature, i) => (
                  <Box key={i} sx={{ fontSize: '1rem', color: '#333' }}>
                    {feature}
                  </Box>
                ))}
              </Box>

              {index === 0 ? (
                user ? (
                  <button className="button success" style={{ color: '#000' }}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </button>
                ) : (
                  <Link to="/auth" className="small-button" style={{ color: '#000' }}>
                    Sign Up
                  </Link>
                )
              ) : index === 1 ? (
                !user ? (
                  <Link to="/auth" className="small-button" style={{ color: '#000' }}>
                    Become A Supporter
                  </Link>
                ) : !isSubscribed ? (
                  <Link to="/subscribe" className="small-button" style={{ color: '#000' }}>
                    Become A Supporter
                  </Link>
                ) : (
                  <button className="button success" style={{ color: '#000' }}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </button>
                )
              ) : null}


            </Box>
          ))}
        </Box>
      </Box>

      {/* Supporter Features Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          bgcolor: '#4e4e73',
          p: { xs: 4, md: 8 },
          gap: '20px',
        }}
      >
        {/* Centered Header */}
        <h1 style={{ textAlign: 'center', margin: 0 }}>'SUPPORTER' FEATURES</h1>

        {/* Supporter Features Grid */}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr' },
            gap: '20px',
            width: '100%',
          }}
        >
          {[
            { title: 'Teams', text: 'Create pre-defined teams of people that can be quickly added to proposals and notified via email.' },
            { title: 'File Upload', text: 'Upload files to your proposals (PDF, PPT, JPEG, etc).' },
            { title: 'Proposal Notifications', text: 'Get notified if someone replies to a proposal you replied to.' },
            { title: 'Unlimited', text: 'Your proposals don’t expire after 30 days. And an unlimited number of people can reply to your proposals.' },
          ].map((benefit, index) => (
            <Box
              key={index}
              sx={{
                p: { xs: 3, md: 5 },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <h3 style={{ marginBottom: '1rem' }}>{benefit.title}</h3>
              <p style={{ margin: 0 }}>{benefit.text}</p>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Testimonial Section */}
      <h1 style={{ textAlign: 'center', marginTop: '2rem' }}>Testimonials</h1>
      <Box
        sx={{
          bgcolor: 'white',
          height: '40vh',
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
        }}
      ></Box>

      {/* Why "Pay What You Can" */}
      <Box
        sx={{
          bgcolor: '#f3d361',
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
          gap: 3, // Adds space between the columns
          p: { xs: 3, md: 5 }, // Adjusts padding for responsiveness
        }}
      >
        {/* Left Content */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            p: { xs: 2, md: 5 }, // Responsive padding
          }}
        >
          <h1>Why “Pay What you Can”?</h1>
          <p>
            We’ve often found ourselves frustrated with arbitrary paywalls and feature restrictions in other tools. We’ve
            experienced the annoyance of hitting unexpected limits or being forced to upgrade just to access a single feature.
            That’s why we decided to do things differently.
          </p>
          <p>
            We believe in providing full access to everyone, regardless of their budget. Our “Pay What You Can” model reflects
            our commitment to inclusivity and our trust in our users. We’ve put a lot of work into building this tool, and we
            want you to use it without restrictions. If you find value in Consensus Check, we trust you’ll contribute what you
            can to help us keep it running and growing. This way, we can focus on improving the tool rather than creating
            artificial barriers.
          </p>
        </Box>

        {/* Right Content */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: { xs: 2, md: 5 }, // Adjust padding for smaller screens
          }}
        >
          <p>Image can go here.</p>
        </Box>
      </Box>


      {/* Learn More */}
      <Box
        sx={{
          bgcolor: 'white',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between', // Adjusts spacing for content
        }}
      >
        {/* Centered Header */}
        <h1 style={{ textAlign: 'center', marginTop: '2rem' }}>LEARN MORE ABOUT CONSENSUS</h1>

          {/* Grid Container */}
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
              alignItems: 'center',
              flexGrow: 1, // Allows the grid to take available space
              width: '100%', // Ensures grid spans full width
            }}
          >
          {/* Left Content */}
          <Box
            sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            }}
          >
            <p>Image can go here.</p>
          </Box>

          {/* Right Content */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              p: 10,
            }}
          >
            <p>Consensus is a cooperative process in which group members develop and agree to support a decision in the best interest of the whole.</p>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Home;
