import React, { useState } from "react";
import { Container, TextField, MenuItem, Button, Typography, Snackbar, Alert } from "@mui/material";

const IssueReport = () => {
  const [bugDescription, setBugDescription] = useState("");
  const [severity, setSeverity] = useState("Medium");
  const [email, setEmail] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    window.alert('Feature still in development.');
    // Simulate bug report submission (Replace with actual API call)
    console.log({ bugDescription, severity, email });
    setOpenSnackbar(true);
    setBugDescription("");
    setSeverity("Medium");
    setEmail("");
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        Report a Bug
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Describe the bug"
          multiline
          fullWidth
          rows={4}
          value={bugDescription}
          onChange={(e) => setBugDescription(e.target.value)}
          required
          margin="normal"
        />
        <TextField
          select
          label="Severity"
          fullWidth
          value={severity}
          onChange={(e) => setSeverity(e.target.value)}
          margin="normal"
        >
          <MenuItem value="Low">Low</MenuItem>
          <MenuItem value="Medium">Medium</MenuItem>
          <MenuItem value="High">High</MenuItem>
        </TextField>
        <TextField
          label="Your Email (Optional)"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          Submit Report
        </Button>
      </form>
      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: "100%" }}>
          Bug report submitted successfully!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default IssueReport;