import ProductDisplay from "../components/ProductDisplay";

export default function Subscribe() {

  
  return (
    <div>
      <ProductDisplay/>
    </div>
  );
}


