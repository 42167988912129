import React, { useState } from "react";
import { Container, TextField, Button, Typography, Card, CardContent } from "@mui/material";

const FeatureRequest = () => {
  const [feature, setFeature] = useState("");
  const [description, setDescription] = useState("");
//   const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    window.alert('Feature still in development.');
    // if (feature.trim() && description.trim()) {
    //   console.log("Feature Request Submitted:", { feature, description });
    //   setSubmitted(true);
    // }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: "2rem" }}>
      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Feature Request
          </Typography>
          {/* {!submitted ? ( */}
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Feature Title"
                variant="outlined"
                margin="normal"
                value={feature}
                onChange={(e) => setFeature(e.target.value)}
                required
              />
              <TextField
                fullWidth
                label="Feature Description"
                variant="outlined"
                margin="normal"
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Submit Request
              </Button>
            </form>
          {/* ) : (
            <Typography variant="h6" color="primary">
              Thank you! Your feature request has been submitted.
            </Typography>
          )}  */}
        </CardContent>
      </Card>
    </Container>
  );
};

export default FeatureRequest;