import React from "react";
import { Box, Typography, Button, Grid2 } from "@mui/material";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#353554", // Updated background color
        color: "#ffffff", // Set text color to white
        padding: "5rem", // Increased height by adding more padding
      }}
    >
      <Grid2 container spacing={25} justifyContent="center" sx={{ mb: 5, pr: 15 }}> {/* Center grids and add more space */}
        {/* About Section */}
        <Grid2 item xs={12} md={4} sx={{ maxWidth: "220px"}}>
          <Typography variant="h6" gutterBottom>
            About
          </Typography>
          <Typography variant="body2" fullWidth sx={{ mb: 2 }}>
          Consensus Check helps you streamline your decision-making process, allowing team members to weigh in asynchronously, avoiding long email threads or missed messages. Discover where your team stands on issues with ease and clarity, empowering you to make informed decisions!
          </Typography>
          <Link href="/privacy" underline="hover" variant="body2" sx={{ color: "#ffffff" }}>
            Privacy Disclaimer
          </Link>
          <br />
          <Link href="/terms" underline="hover" variant="body2" sx={{ color: "#ffffff" }}>
            Terms of Service
          </Link>
        </Grid2>

        {/* Get Started Section */}
        <Grid2 item xs={12} md={4} sx={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}> {/* Move content slightly to the left */}
          <Typography variant="h6" gutterBottom>
            Get Started
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mb: 2 }}
            component={Link}
            to="/create"
          >
            Create a Proposal
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            fullWidth 
            sx={{ mb: 2 }}
            component={Link}
            to="/auth"
          >
            Sign Up for an Account
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            fullWidth 
            sx={{ mb: 2 }}
            component={Link}
            to="/basics"
          >
            Learn About Consensus
          </Button>
        </Grid2>

        {/* Contact Section */}
        <Grid2 item xs={12} md={4} sx={{ textAlign: "right" }}> {/* Align content to the right */}
          <Typography variant="h6" gutterBottom>
            Contact
          </Typography>
          <Box>
          <Button
              component={Link}
              to="/"
              sx={{
                textTransform: "none",
                textDecoration: "underline",
                color: "primary",
                background: "none",
                "&:hover": { background: "none", textDecoration: "underline" }
              }}
            >
              Email Us
            </Button>
            <br />
            <Button
              component={Link}
              to="/issue-report"
              sx={{
                textTransform: "none",
                textDecoration: "underline",
                color: "primary",
                background: "none",
                "&:hover": { background: "none", textDecoration: "underline" }
              }}
            >
              Report a Bug
            </Button>
            <br />
            <Button
              component={Link}
              to="/feature-request"
              sx={{
                textTransform: "none",
                textDecoration: "underline",
                color: "primary",
                background: "none",
                "&:hover": { background: "none", textDecoration: "underline" }
              }}
            >
             Feature Request 
            </Button>
          </Box>
        </Grid2>
      </Grid2>
      <Box sx={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography>
          © 2024. All Rights Reserved.
          Website Support by Jordan Levelle
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;





